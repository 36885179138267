<template>
    <div>
        <div v-if="isMounted">
            <div v-if="!surveyAnswered">
                <big-title-color variant-class="primary">
                    <template v-slot:text>¡En la Red te <span>escuchamos</span>!</template>.
                </big-title-color>
                <vx-card class="mt-base">
                    <vs-table stripe :data="questions" noDataText="Sin preguntas">
                        <template slot="header">
                            <h3 class="bold text-primary" >Cuéntanos sobre tu experiencia con el proveedor.</h3>
                        </template>
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                                <template v-if="tr.type == 'SP_QUESTION' && tr.data_type == 'combo'">
                                    <vs-td>
                                        <p class="bold"> {{ tr.question }}</p>
                                        <p>{{ tr.indications }}</p>
                                    </vs-td>
                                    <vs-td v-for="(answ, indexAnsw) in tr.responses" :key="indexAnsw">
                                        <template v-if="!isYesOrNotQuestion(tr)">
                                            <label class="vs-component con-vs-radio vs-radio-primary">
                                                <input 
                                                    type="radio" 
                                                    class="vs-radio--input"
                                                    v-model="answers[indextr]"
                                                    :name="`a_${tr.id}`" 
                                                    :value="answ.value"  
                                                    >
                                                <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                            </label>
                                        </template>
                                        <template v-else>
                                            <label v-if="answ.value == 1 || answ.value == 5" class="vs-component con-vs-radio vs-radio-primary">
                                                <input 
                                                    type="radio" 
                                                    class="vs-radio--input"
                                                    v-model="answers[indextr]"
                                                    :name="`a_${tr.id}`" 
                                                    :value="answ.value"  
                                                    >
                                                <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                                <span class="bold ml-1"> {{ answ.value == 1 ? "No" : "SI" }}</span>
                                            </label>
                                        </template>
                                    </vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <div class="vx-col bold" style="padding: .5rem 1rem .5rem 1rem;">
                        {{questions[getTextQuestionIndedx(true)].question}}
                        <vs-textarea maxlength="255" v-model="answers[getTextQuestionIndedx(true)]"/>
                    </div>
                    <vs-table stripe :data="questions" noDataText="Sin preguntas">
                        <template slot="header">
                            <h3 class="bold text-primary">Cuéntanos sobre tu experiencia con RedGirasol.</h3>
                        </template>
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                                <template v-if="tr.type == 'RG_QUESTION' && tr.data_type == 'combo'">
                                    <vs-td>
                                        <p class="bold"> {{ tr.question }}</p>
                                        <p>{{ tr.indications }}</p>
                                    </vs-td>
                                    <vs-td v-for="(answ, indexAnsw) in tr.responses" :key="indexAnsw">
                                        <label class="vs-component con-vs-radio vs-radio-primary">
                                            <input 
                                                type="radio" 
                                                class="vs-radio--input"
                                                v-model="answers[indextr]"
                                                :name="`a_${tr.id}`" 
                                                :value="answ.value" 
                                                > 
                                            <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                        </label>
                                    </vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <div class="vx-col bold" style="padding: .5rem 1rem .5rem 1rem;">
                        {{questions[getTextQuestionIndedx(false)].question}}
                        <vs-textarea maxlength="255" v-model="answers[getTextQuestionIndedx(false)]"/>
                    </div>
                    <vs-button class="mt-4" @click="validateAnswers()">Enviar</vs-button>
                </vx-card>
            </div>
            <vx-card v-else style="padding: 4.5rem;" class="mt-16 mb-20 text-center w-full">
                <img src="@/assets/images/figs/fig9.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">        
                <h2 class="mb-6 mt-6">Gracias por compartirnos tu experiencia,
                                <br>juntos hacemos que nuestra Red
                                <br>sea la más brillante.</h2>
                <vs-button @click="goToHomePage()">Volver al inicio</vs-button>
            </vx-card>
        </div>
    </div>
</template>

<script>
const columnDefinitions = [
    {title: "Pregunta", key: "question", sortable: false }
    , {title: "1", key: "question_1", sortable: false }
    , {title: "2", key: "question_2", sortable: false }
    , {title: "3", key: "question_3", sortable: false }
    , {title: "4", key: "question_4", sortable: false }
    , {title: "5", key: "question_5", sortable: false }

]

export default {
    name: 'applicant-project-survey-feedback',
    props: ["pid"],
    data() {
        return {
            questions: []
            , columns: columnDefinitions
            , answers: []
            , isMounted: false
            , surveyAnswered: false
        }
    },
    async mounted(){
        this.isMounted = false;
        this.showLoading(true);
        await this.validatePage();
        this.isMounted = true
        this.showLoading(false)
    },
    methods: {
        async getApplicantsSurveyFeedback() {
            this.showLoading(true);
            let response = await axios.get(`/api/v1/applicant_satisfaction_survey/getApplicantsSurveyFeedback`);
            this.questions = response.data;
            this.isMounted = true;
            this.showLoading(false);
        },
        validateAnswers() {
            let questionsLength = this.questions.length;
            let isFull = false;
            for (let i = 0; i <questionsLength; i++) {
                if(typeof this.answers[i] === 'undefined'){
                    isFull = false;
                    break;
                }
                isFull = true;
            }
            if(isFull){
                if(this.ApplicantId != 0 && this.pid != 0){
                    this.saveSurveyAnswers();
                } else {
                    this.failedOperationNotif();
                }
            } else {
                this.missingFieldsNotif("Preguntas faltantes", "Por favor responda todas las preguntas desplegadas.");
            }  
        },
        saveSurveyAnswers() {
            let answers = [];
            let counter = 0;
            this.questions.forEach(question => {
                let value = this.answers[counter] != undefined ? this.answers[counter] : "Sin comentarios.";
                let answer = {
                    fg_survey_question_id: question.id
                    , value: value
                    , client_id: this.ApplicantId
                    , project_id: this.pid
                }
                answers.push(answer);
                counter++;
            });
            this.postSurveyAnswers(answers);
        },
        async postSurveyAnswers(answers) {
            this.showLoading(true);
            let payload = { applicant_survey_feedback_answers: answers };
            try {
                let response = await axios.post("api/v1/applicant_satisfaction_survey/saveApplicantsSurveyFeedback", payload);
                if(response.status == 200 && response.statusText == "OK"){
                    this.surveyAnswered = true;
                    this.saveSuccessNotif();
                }
            } catch(err) {
                this.failedOperationNotif();
            }
            this.showLoading(false);
        },
        async validatePage() {
            if(this.pid != null){
                const auth = await this.authorizeViewSurvey();
                if(!auth){
                    await this.$router.replace({name: 'inicioSolicitante'});
                    this.showLoading(false)
                    return;
                }
                if (!this.surveyAnswered) {
                    await this.getApplicantsSurveyFeedback();                    
                }
            } else {
                await this.$router.replace({name: 'inicioSolicitante'});
            }
        },
        async authorizeViewSurvey(){
            try {
                let res = await axios.get(`api/v1/applicant/${this.ApplicantId}/authorizeViewSurvey/${this.pid}`);
                this.surveyAnswered = res.data;
                return true;
            }
            catch (e){
                return false;
            }
        },
        updateFooter (val) {
            this.footerType = val
        },
        currentYear(){
            return new Date().getFullYear();
        },
        getTextQuestionIndedx(isSupplier) {
            // Si pudieras mejorar algo de tu proveedor, ¿qué sería?
            // Si pudieras mejorar algo de RedGirasol, ¿qué sería?
            let text = "Si pudieras mejorar algo de RedGirasol, ¿qué sería?";
            if(isSupplier){
                text = "Si pudieras mejorar algo de tu proveedor, ¿qué sería?";
            }
            let index = this.questions.findIndex( x => x.question == text);
            if(index != -1){
                return index;
            } else {
                console.warn("Preguntas no definidas");
                return 99;
            }
        },
        isYesOrNotQuestion(question){
            const FIRST_QUESTION = '¿Fueron instalados los equipos acordados?';
            const SECOND_QUESTION = '¿El instalador realizó los trámites antes CFE para tu cambio de medidor?';
            const THIRD_QUESTION = '¿Cumplieron con lo que te había cotizado y prometido?';
            if(question.question == FIRST_QUESTION || question.question == SECOND_QUESTION || question.question == THIRD_QUESTION){
                return true;
            } 
            return false;
        },
        async goToHomePage(){
            await this.$router.push({name: 'inicioSolicitante'});
        },
    }
}
</script>